<template>
  <div style="height: 100%">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>数据分析</el-breadcrumb-item>
        <el-breadcrumb-item>人员分析</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="display: flex;padding: 10px;height: 100%">
      <div class="left">
        <div class="top" @click="getDepartments" style="cursor: pointer">
          <Icon type="ios-git-network" style="color: royalblue;margin-right: 12px;transform: rotate(180deg)" />
          <span>{{ comName }}</span>
        </div>
        <div class="tree">
          <el-tree ref="tree" :data="treeData" highlight-current>
            <div
              slot-scope="{ node, data }"
              @click="selectTree(data.detail.departmentId)"
              style="font-size: 14px;display: flex;justify-content: space-between;width: 100%"
            >
              <Tooltip :content="node.data.title" :disabled="node.data.title.length <= 12" transfer max-width="250px">
                <div style="display: flex;align-items: center;">
                  <Icon :size="16" color="#2598E5" style="margin-right: 4px" type="md-people" />
                  <div>
                    {{ node.data.title.length > 12 ? node.data.title.substring(0, 12) + '...' : node.data.title }}
                  </div>
                </div>
              </Tooltip>
            </div>
          </el-tree>
        </div>
      </div>
      <div class="right">
        <el-table @row-click="toDetail" :data="tableData" height="calc(100% - 53px)" :loading="loading">
          <el-table-column prop="realName" label="姓名" show-overflow-tooltip />
          <el-table-column prop="jobNumber" label="学号" show-overflow-tooltip />
          <el-table-column prop="postName" label="专业" show-overflow-tooltip />
        </el-table>
        <Page
          class="yt-page"
          :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
          @on-change="changePage"
          :page-size-opts="[10, 20, 40, 100]"
          @on-page-size-change="changeSize"
          :total="total"
          show-sizer
          show-elevator
          show-total
          style="text-align: right;margin: 10px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import functionApi from '@api/functionApi'
import exam from '@api/exam'
import departmentApi from '@/api/department'

export default {
  name: 'Rank',
  data() {
    const sWidth = window.screen.width
    return {
      comName: '',
      th: document.body.clientHeight - 305,
      loading: false,
      tableH: document.body.clientHeight - 190,
      treeData: [],
      tableData: [],
      postId: 0,
      page: 0,
      size: 10,
      total: 0
    }
  },
  created() {
    this.getDepartments()
    // functionApi.getAllPostById(0).then(res => {
    //   this.treeData = res.res
    //   this.postId = this.treeData[0].id
    //   this.$nextTick(() => {
    //     this.$refs.rankTree.setCurrentKey(this.postId)
    //   })
    //   this.getRank()
    // })
  },
  beforeRouteEnter(to, from, next) {
    if (from.path.includes('/analysis/assessment')) {
      to.meta.keepAlive = true
    } else {
      to.meta.keepAlive = false
    }
    next()
  },
  methods: {
    selectTree(data) {
      if (data) {
        this.departmentId = data
      }
      this.loading = true
      departmentApi
        .getDepartmentStaff(this.departmentId, this.page, this.size)
        .then(res => {
          this.total = res.res.total
          res.res.data.forEach(item => {
            item.gender = item.gender ? '男' : '女'
          })
          this.tableData = res.res.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    appendTreeNode(node, data) {
      for (let i = 0, len = node.children.length; i < len; i++) {
        data.push({
          title: node.children[i].name,
          selected: false,
          expand: false,
          children: [],
          detail: node.children[i]
        })
        if (node.children[i].children.length) {
          this.appendTreeNode(node.children[i], data[data.length - 1].children)
        }
      }
    },
    getDepartments() {
      this.$store.dispatch('department/getDepartmentTree').then(res => {
        let tree = res.res
        this.comName = res.res.name
        this.treeData = []
        this.appendTreeNode(tree, this.treeData)
        // this.departmentId = this.treeData[0].detail.departmentId
        this.departmentId = tree.departmentId
        this.selectTree(this.departmentId)
      })
    },
    toDetail(row) {
      this.$router.push({ name: 'staffDetail', params: { userId: row.userId } })
    },
    initTable(val) {
      if (val.id) {
        this.postId = val.id
        this.page = 0
        this.size = 10
        this.total = 0
        this.getRank()
      }
    },
    getRank() {
      this.loading = true
      exam
        .getRank(this.postId, this.page, this.size)
        .then(res => {
          this.total = res.res.total
          this.tableData = res.res.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    changePage(val) {
      this.page = val - 1
      this.selectTree()
    },
    changeSize(val) {
      this.size = val
      this.selectTree()
    }
  }
}
</script>

<style scoped lang="less">
.left {
  .top {
    height: 56px;
    background: #e4e9ef;
    font-size: 16px;
    color: #3a4e64;
    font-weight: 400;
    padding: 16px 23px;
    display: flex;
    align-items: center;
  }
  .tree {
    padding: 15px 12px;
    text-align: left;
    background: white;
    height: calc(100% - 56px);
    overflow: auto;
  }
}
.right {
  height: 100%;
  margin-left: 10px;
  box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
  width: calc(100% - 313px);
}
</style>
